import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../containers/SaasModern/Documentation';
import download_run from '../../../../../../common/src/assets/image/download_run.png';
import install_license_terms from '../../../../../../common/src/assets/image/install_license_terms.png';
import install_exit_excel from '../../../../../../common/src/assets/image/install_exit_excel.png';
import install_progress from '../../../../../../common/src/assets/image/install_progress.png';
import ribbon from '../../../../../../common/src/assets/image/ribbon.png';

const Documentation = () => {
  const content = (
    <div id="help">
      <p>
        PrintableCal can be installed on Windows 10, 8.1, 8, 7, and Vista. It
        also requires Microsoft Excel, Word, or PowerPoint 2007 or later. Any
        other missing prerequisites, such as Microsoft .NET Framework 4.5, will
        be detected and installed.
      </p>
      <p>
        Even though PrintableCal is free to install, it does not include any
        other toolbars, adware, malware, or 3rd party software. We vehemently
        oppose the trickery that most other free software installations try to
        pull.
      </p>
      <h2>Step-By-Step Installation Instructions</h2>
      <ol className="numbered">
        <li>
          If you haven't already,{' '}
          <Link to="/Download">download the PrintableCal installer</Link>.
        </li>
        <li>
          Open or run the file after it finishes downloading.
          <br />
          <Image alt="" src={download_run} style={{ width: 428 }} />
        </li>
        <li>
          The PrintableCal Setup window will appear. Click the{' '}
          <strong>I agree to the license terms and conditions</strong> box after
          reviewing the license terms (if you are so inclined), and then click
          the <strong>Next</strong> button.
          <br />
          <Image alt="" src={install_license_terms} style={{ width: 450 }} />
        </li>
        <li>
          The installer can't run if Excel, Word, or PowerPoint are open. If you
          see a red X next to <strong>Running applications</strong>, exit all
          instances of Excel, Word, and PowerPoint. Click the{' '}
          <strong>Refresh</strong> button. If you still see a red X, try
          rebooting. Otherwise, click the <strong>Install </strong>button to
          continue.
          <br />
          <Image alt="" src={install_exit_excel} style={{ width: 450 }} />
        </li>
        <li>
          If a User Access Control window appears, click the Yes button to
          continue the setup. Any missing prerequisites (except Microsoft
          Office) will be automatically installed. A progress bar will show the
          installation status. On most computers, installation should only take
          a few seconds.
          <br />
          <Image alt="" src={install_progress} style={{ width: 450 }} />
        </li>
        <li>
          After the installation has completed, a window will appear to let you
          know PrintableCal has been successfully installed.
        </li>
        <li>
          If Excel or Word are currently running, save and close all documents.
          PrintableCal will appear on the ribbon after starting Excel or Word.
          <br />
          <Image alt="" src={ribbon} style={{ width: 850 }} />
        </li>
      </ol>
      <h2 id="troubleshooting">Troubleshooting</h2>
      <p>
        If the PrintableCal tab doesn't appear on the ribbon menu in Excel,
        Word, or PowerPoint, try rebooting.
      </p>
      <p>
        If rebooting doesn't fix it, download and run the{' '}
        <a href="https://www.printablecal.com/download/AddPrintableCal.exe">
          Add PrintableCal utility
        </a>{' '}
        under the account where the PrintableCal tab isn't appearing. This
        utility does not require admin privileges.
      </p>
      <p>
        If the <strong>Add PrintableCal</strong> utility doesn't solve the
        problem, download the registry fix linked below, depending on which
        version of Office you're using:
      </p>
      <ul>
        <li>
          <a href="https://www.printablecal.com/download/PrintableCalRegistryFix.zip">
            PrintableCal registry fix for Office 2010 and later
          </a>
        </li>
        <li>
          <a href="https://www.printablecal.com/download/PrintableCalRegistryFix_Office2007.zip">
            PrintableCal registry fix for Office 2007 only
          </a>
        </li>
      </ul>
      <p>
        After downloading, extract the file to a location on your hard drive.
        Also close all instances of Excel and Word. To add PrintableCal to
        Excel, double-click the file named <b>PrintableCal - Excel.reg</b>. To
        add to Word, double-click <b>PrintableCal - Word.reg</b>. Note, you’ll
        need administrator rights for the scripts to run.
      </p>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/How-To/Install-PrintableCal"
      commentsId="commentsplus_post_159_489"
      title="How To Install PrintableCal"
      description="PrintableCal is an Office add-in for creating printable calendars. It runs on Windows and requires Microsoft Excel, Word, or PowerPoint 2007 or later."
      keywords="printable calendar, Excel calendar, Word calendar, Windows calendar, 2020 calendar, Excel template, Word template, Gantt Chart, holiday calendar, date picker"
      content={content}
      showDownload={false}
    />
  );
};

export default Documentation;
